/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

import { rhythm } from '../utils/typography';

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata;
  return (
    <div
      style={{
        display: `flex`,
        alignItems: 'center',
        marginBottom: rhythm(1.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          margin: `0 ${rhythm(3 / 4)}`,
          minWidth: 90,
          minHeight: 90,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `100%`,
        }}
      />
      <div>
        <div>
          {author.summary.split('Alex')[0]}
          <strong>Alex</strong>
          {author.summary.split('Alex')[1]}
        </div>
        <div style={{ marginTop: rhythm(1 / 3) }}>
          Hit me up on Twitter{' '}
          <a
            target="_blank"
            rel="noreferrer"
            style={{ fontWeight: 'bold' }}
            href={`https://twitter.com/${social.twitter}`}
          >
            @{social.twitter}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Bio;
